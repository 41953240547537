<template>
    <page-container class="card-info-container">
      <van-nav-bar title="快捷支付" left-arrow @click-left="$router.back()">
      </van-nav-bar>
        <div class="cardItem" v-for="item in quickPayList">
            <img src="../../img/categories//cardLogo.png" alt="">
            <div class="delete" @click="delquickPay(item.tokenNo)">删除</div>
            <div class="backName">
              {{ item.bankName }}
            </div>
            <!-- <div class="cardType">
              储蓄卡
            </div> -->
            <div class="cardId">
              {{ item.cardId }}
            </div>
            <div class="telNo">
              {{ item.telNo }}
            </div>

        </div>
        <!-- v-if="quickPayList.length==0" -->
        <div class="addQuick" @click="addQuickPay" v-if="!loading && quickPayList.length < 3">
          <van-icon name="plus" size="26"/>
            <div>添加银行卡</div>
        </div>
    </page-container>
  </template>

  <script>
  import { Dialog } from "vant";
  import { getFastCards , delFastCard} from "@/api/user";
  import { Toast } from "vant";

  export default {
    name: "userAuthentication",
    data() {
      return {
        quickPayList:[],
        loading: false,
        reSubmitCountDownTime: 0,
      };
    },
    computed: {
    },
    async created() {
      this.init()
    },
    methods: {
      async init(){
        Toast.loading({ mask: true });
        this.loading = true;
         await getFastCards().then((res) => {
            this.quickPayList = res
        });
        this.loading = false;
        Toast.clear();
      },
      addQuickPay(){
        this.$router.push({
            path: '/addQuickPay',
        });
      },
      delquickPay(e){
        Dialog.confirm({
            title: `是否删除该快捷支付？`,
            // message: '弹窗内容',
            beforeClose: (action, done) => {
            if (action === 'confirm') {
                Toast.loading({});
                delFastCard(e).then(res => {
                Toast.clear();
                if (res === true) {
                    this.init();
                }
                });
            }
            done();
            },
        }).catch(() => {
        });
      },
    },
  };
  </script>

  <style lang="less" scoped>
  .card-info-container{
    .cardItem{
      border-radius: 10px;
      background-color: #fff;
      margin:20px;
      padding: 20px;
      position: relative;
      img{
          width:80px;
          position: absolute;
          right:20px;
          top:20px;
      }
      .delete{
        position: absolute;
        top:0;
        right:0;
        color:#fff;
        padding: 4px 10px;
        background-color:#212c67 ;
      }
      .backName{
        font-size: 20px;
        font-weight: 900;
        margin-bottom:20px;
      }
      .cardType{
        margin:20px 0;
        color:#b0b0b0
      }
      .cardId{
        font-size: 15px;
      }
      .telNo{
        margin-top:10px;
        font-size: 15px;
      }

    }
  }
  .addQuick{
    margin:10px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding:16px 20px;
    .van-icon{
     margin-right:20px;
    }
  }
  ::v-deep .van-nav-bar {
    background-color: #212c67;
    .van-nav-bar__title{
      color:#fff;
    }
    .van-icon {
      color: #fff;
      font-size: 18px;
    }
  }
  </style>

